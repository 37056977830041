import { getStoreDataByStoreName } from "@/data/hcl/sdk/utils";
export let storeId = '';
export let langId = '';
export let catalogId = '';
export let currency = '';

function getStoreIdentifierDirectoryMap(locale: string) {      
  // Devo enumerare per intero tutte le variabili perchè altrimenti queste 
  // non verrebbero valorizzate in fase di build e non sarebbero disponibili client side.
  // Vedi: https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
  switch (locale) {
    case 'it':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_it || '';
    case 'fr':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_fr || '';
    case 'es':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_es || '';
    case 'en':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_en || '';
    case 'de':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_de || '';
    case 'pl':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_pl || '';
    case 'pt':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_pt || '';
    case 'cs':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_cs || '';
    case 'ru':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_ru || '';
    case 'sl':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_sl || '';
    case 'hr':
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_hr || '';
    default:
      return process.env.NEXT_PUBLIC_LOCALE_STORE_MAPPING_it || '';
  }
}

export const getStore = async (locale: string) => {
    //TODO: cachare le info sullo store (localStorage non si può usare nel SSR)
  /* let storeIdentifier = getStoreItem(locale); */
  let storeData: any;
 /*  if(!storeIdentifier){ */
      let directory = getStoreIdentifierDirectoryMap(locale);
      const { data } = await getStoreDataByStoreName(directory);
      storeData = data?.storeFindByStoreNameAndUsage?.resultList[0];
      langId = data?.langId;
      if(storeData.storeId){
        storeId = storeData.storeId;
      }
      if(storeData.defaultCatalogId){
        catalogId = storeData.defaultCatalogId;
      }
      if(storeData.defaultLanguageId && !data?.langId){
        langId = storeData.defaultLanguageId;
      }
      if(storeData.defaultCurrency){
        currency = storeData.defaultCurrency;
      }
  /* } */
  return storeData;
}

/* const getStoreItem = (locale: string) => {
  let storeIdentifier;
  if(localeSelected && localeSelected !== locale) {
    storeIdentifier = storeId;
    localeSelected = locale;
  }
  return storeIdentifier;
} */